import * as Tone from "tone"
import toWav from "audiobuffer-to-wav"
import {
    ALIEN_CHORUS,
    DELICATE_WIND_PART,
    DROP_PULSE,
    LECTRIC,
    MARIMBA,
    STEELPAN,
    SUPER_SAW,
    TREE_TRUNK,
    GLASS_HARMONY,
    COSMIC_PAD,
    DIGITAL_BELL,
    WARM_STRINGS,
    ELECTRIC_PIANO,
    SYNTH_BASS,
    CRYSTAL_KEYS,
    DEEP_ORGAN,
    BRIGHT_TRUMPET,
    SOFT_FLUTE,
    METALLIC_DRUM,
    SPACE_VOICE,
    ELECTRO_HARP,
    VINTAGE_ORGAN,
    FUTURE_PAD,
    DIGITAL_CHIME,
} from "../sounds/synth/Miscellaneous.js"
import {
    BAH,
    BASS_GUITAR,
    BASSY,
    BRASS_CIRCUIT,
    COOL_GUY,
    PIANOETTA,
    PIZZ,
    NICE_BOY,
} from "../sounds/monosynth/Miscellaneous.js"
import {
    KALIMBA,
    ELECTRIC_CELLO,
    THIN_SAWS,
    ROUGH_FLY,
    ROUGH_MAN,
    ROUGH_BUILDING,
} from "../sounds/fmsynth/Miscellaneous.js"
import { FLUTE } from "../sounds/samples/NoteToSampleMaps/FluteNoteToSample.js"
import { CELLO } from "../sounds/samples/NoteToSampleMaps/CelloNoteToSample.js"
import { PIANO } from "../sounds/samples/NoteToSampleMaps/PianoNoteToSample.js"
import { VIOLIN } from "../sounds/samples/NoteToSampleMaps/ViolinNoteToSample.js"
import { TRUMPET } from "../sounds/samples/NoteToSampleMaps/TrumpetNoteToSample.js"
import { GUITAR_ACOUSTIC } from "../sounds/samples/NoteToSampleMaps/GuitarAcousticNoteToSample.js"
import { GUITAR_ELECTRIC } from "../sounds/samples/NoteToSampleMaps/GuitarElectricNoteToSample.js"
import { BASS_ELECTRIC } from "../sounds/samples/NoteToSampleMaps/BassElectricNoteToSample.js"
import {
    showToastInfo,
    showToastSuccess,
    showToastError,
} from "../style/ShowToast.js"

// Mapping between instrument names and their corresponding noteToSampleNameMap
const instrumentToSampleMap = {
    Cello: CELLO,
    Flute: FLUTE,
    Piano: PIANO,
    Violin: VIOLIN,
    Trumpet: TRUMPET,
    "Guitar acoustic": GUITAR_ACOUSTIC,
    "Guitar electric": GUITAR_ELECTRIC,
    "Bass electric": BASS_ELECTRIC,
    // Add other instruments and their mappings here
}

const instrumentToSynthConfigMap = {
    "Alien chorus": ALIEN_CHORUS,
    "Delicate wind part": DELICATE_WIND_PART,
    "Drop pulse": DROP_PULSE,
    Lectric: LECTRIC,
    Marimba: MARIMBA,
    Steelpan: STEELPAN,
    "Super saw": SUPER_SAW,
    "Tree trunk": TREE_TRUNK,
    "Glass harmony": GLASS_HARMONY,
    "Cosmic pad": COSMIC_PAD,
    "Digital bell": DIGITAL_BELL,
    "Warm strings": WARM_STRINGS,
    "Electric piano": ELECTRIC_PIANO,
    "Synth bass": SYNTH_BASS,
    "Crystal keys": CRYSTAL_KEYS,
    "Deep organ": DEEP_ORGAN,
    "Bright trumpet": BRIGHT_TRUMPET,
    "Soft flute": SOFT_FLUTE,
    "Metallic drum": METALLIC_DRUM,
    "Space voice": SPACE_VOICE,
    "Electro harp": ELECTRO_HARP,
    "Vintage organ": VINTAGE_ORGAN,
    "Future pad": FUTURE_PAD,
    "Digital chime": DIGITAL_CHIME,
}

const instrumentToMonosynthConfigMap = {
    Bah: BAH,
    "Bass guitar": BASS_GUITAR,
    Bassy: BASSY,
    "Brass circuit": BRASS_CIRCUIT,
    "Cool guy": COOL_GUY,
    Pianoetta: PIANOETTA,
    Pizz: PIZZ,
    "Nice boy": NICE_BOY,
}

const instrumentToFMSynthConfigMap = {
    Kalimba: KALIMBA,
    "Electric cello": ELECTRIC_CELLO,
    "Thin saws": THIN_SAWS,
    "Rough fly": ROUGH_FLY,
    "Rough man": ROUGH_MAN,
    "Rough building": ROUGH_BUILDING,
}

function formatInstrumentName(name) {
    return name.toLowerCase().replace(" ", "_")
}

export const getSynth = async (category, instrumentName) => {
    switch (category) {
        case "Samples":
            const noteToSampleMapping = instrumentToSampleMap[instrumentName]
            const formattedInstumentName = formatInstrumentName(instrumentName)
            const samplesFolderUrl = process.env.REACT_APP_SAMPLES_URL

            if (noteToSampleMapping) {
                return new Tone.Sampler(
                    noteToSampleMapping,
                    null,
                    `${samplesFolderUrl}/${formattedInstumentName}/`
                ).toDestination()
            }
            break
        case "Synth":
            return new Tone.Synth({
                ...instrumentToSynthConfigMap[instrumentName],
            }).toDestination()
            break
        case "Monosynth":
            return new Tone.MonoSynth({
                ...instrumentToMonosynthConfigMap[instrumentName],
            }).toDestination()
            break
        case "FMSynth":
            return new Tone.MonoSynth({
                ...instrumentToFMSynthConfigMap[instrumentName],
            }).toDestination()
            break
        default:
            return new Tone.Synth().toDestination()
    }
}

export const loadSynth = async (instrument) => {
    const { name, category } = instrument

    const synth = await getSynth(category, name)

    // wait for it to load
    await Tone.loaded()

    synth.volume.value = instrument.volume

    return synth
}

export const disposeSynths = (synths) => {
    synths.forEach((synth) => synth.dispose())
}

export const handleWAVDownloadClick = async (midi, selectedInstruments) => {
    try {
        const toastInfo = showToastInfo(
            "Preparing your WAV file for download..."
        )

        const renderedBuffer = await getRenderedBuffer(
            midi,
            selectedInstruments
        )

        downloadWavFile(renderedBuffer)
        toastInfo.dismiss()
        showToastSuccess("Your WAV file is ready for download!", 5000)
    } catch (error) {
        console.error("An error occurred:", error)
        showToastError("An error occurred while preparing your WAV file.", 5000)
    }
}

export const getRenderedBuffer = async (midi, selectedInstruments) => {
    const totalDuration = midi.duration
    const renderedBuffer = await Tone.Offline(async (offlineContext) => {
        const offlineSynths = await loadAllSynths(
            midi.tracks,
            selectedInstruments,
            offlineContext
        )
        triggerAllNotes(midi.tracks, offlineSynths)
    }, totalDuration)

    return renderedBuffer
}

export const loadAllSynths = async (
    midiTracks,
    selectedInstruments,
    offlineContext
) => {
    return await Promise.all(
        midiTracks.map(async (track, i) => {
            const instrument = selectedInstruments[i]
            const synth = await loadSynth(instrument, false)
            if (synth) {
                synth.context = offlineContext
            } else {
                console.error("Synth not loaded for instrument:", instrument)
            }
            return synth
        })
    )
}

// Helper to trigger all notes
const triggerAllNotes = (midiTracks, offlineSynths) => {
    for (let i = 0; i < midiTracks.length; i++) {
        const track = midiTracks[i]
        const offlineSynth = offlineSynths[i]
        if (offlineSynth) {
            for (const note of track.notes) {
                offlineSynth.triggerAttackRelease(
                    note.name,
                    note.duration,
                    note.time,
                    0.2
                )
            }
        }
    }
}

// Function to create a WAV Blob and trigger a download
const downloadWavFile = (renderedBuffer) => {
    const wav = toWav(renderedBuffer)
    const blob = new Blob([wav], { type: "audio/wav" })
    const url = URL.createObjectURL(blob)

    const a = document.createElement("a")
    a.href = url
    a.download = "output.wav"
    a.click()

    // Try this:
    // // Revoke the URL after a few seconds to free up resources
    // setTimeout(() => {
    //     URL.revokeObjectURL(url)
    // }, 10000) // 10000 milliseconds = 10 seconds
}

export const createBufferedMidiEvents = async (tracks, activeSynths, selectedInstruments) => {
    const hasSolo = selectedInstruments.some((instrument) => instrument.isSolo)
    let parts = []
    let maxDuration = 0

    for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i]
        const trackSynth = activeSynths[i]
        const instrument = selectedInstruments[i]
        const volume = getTrackVolume(trackSynth, instrument, hasSolo)

        const part = new Tone.Part((time, note) => {
            trackSynth.triggerAttackRelease(
                note.name,
                note.duration,
                time,
                volume
            )
        }, track.notes)

        parts.push(part)

        maxDuration = Math.max(maxDuration, track.duration)
    }

    return { parts, maxDuration }
}

const getTrackVolume = (trackSynth, instrument, hasSolo) => {
    if (hasSolo) {
        return instrument.isSolo && !instrument.isMuted ? trackSynth.volume.value : 0;
    }
    return instrument.isMuted ? 0 : trackSynth.volume.value;
};
