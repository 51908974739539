export const SUPER_SAW = {
    oscillator: {
        type: "fatsawtooth",
        count: 5,
        spread: 40,
    },
    envelope: {
        attack: 0.01,
        decay: 0.1,
        sustain: 0.5,
        release: 0.4,
        attackCurve: "exponential",
    },
}

export const TREE_TRUNK = {
    oscillator: {
        type: "sine",
    },
    envelope: {
        attack: 0.001,
        decay: 0.1,
        sustain: 0.1,
        release: 1.2,
    },
}

export const STEELPAN = {
    oscillator: {
        type: "fatcustom",
        partials: [0.2, 1, 0, 0.5, 0.1],
        spread: 40,
        count: 3,
    },
    envelope: {
        attack: 0.001,
        decay: 1.6,
        sustain: 0,
        release: 1.6,
    },
}

export const MARIMBA = {
    oscillator: {
        partials: [1, 0, 2, 0, 3],
    },
    envelope: {
        attack: 0.001,
        decay: 1.2,
        sustain: 0,
        release: 1.2,
    },
}

export const LECTRIC = {
    portamento: 0.2,
    oscillator: {
        type: "sawtooth",
    },
    envelope: {
        attack: 0.03,
        decay: 0.1,
        sustain: 0.2,
        release: 0.02,
    },
}

export const DROP_PULSE = {
    oscillator: {
        type: "pulse",
        width: 0.8,
    },
    envelope: {
        attack: 0.01,
        decay: 0.05,
        sustain: 0.2,
        releaseCurve: "bounce",
        release: 0.4,
    },
}

export const DELICATE_WIND_PART = {
    portamento: 0.0,
    oscillator: {
        type: "square4",
    },
    envelope: {
        attack: 2,
        decay: 1,
        sustain: 0.2,
        release: 2,
    },
}

export const ALIEN_CHORUS = {
    oscillator: {
        type: "fatsine4",
        spread: 60,
        count: 10,
    },
    envelope: {
        attack: 0.4,
        decay: 0.01,
        sustain: 1,
        attackCurve: "sine",
        releaseCurve: "sine",
        release: 0.4,
    },
}

export const GLASS_HARMONY = {
    oscillator: {
        type: "triangle",
    },
    envelope: {
        attack: 0.05,
        decay: 0.3,
        sustain: 0.7,
        release: 0.5,
    },
};

export const COSMIC_PAD = {
    oscillator: {
        type: "fatsine",
        count: 5,
        spread: 50,
    },
    envelope: {
        attack: 1.5,
        decay: 0.5,
        sustain: 0.8,
        release: 1.5,
    },
};

export const DIGITAL_BELL = {
    oscillator: {
        type: "square",
    },
    envelope: {
        attack: 0.01,
        decay: 0.2,
        sustain: 0.3,
        release: 0.8,
    },
};

export const WARM_STRINGS = {
    oscillator: {
        type: "sawtooth",
    },
    envelope: {
        attack: 0.3,
        decay: 0.2,
        sustain: 0.6,
        release: 1.0,
    },
};

export const ELECTRIC_PIANO = {
    oscillator: {
        type: "sine",
    },
    envelope: {
        attack: 0.02,
        decay: 0.3,
        sustain: 0.5,
        release: 0.5,
    },
};

export const SYNTH_BASS = {
    oscillator: {
        type: "square",
    },
    envelope: {
        attack: 0.05,
        decay: 0.1,
        sustain: 0.8,
        release: 0.3,
    },
};

export const CRYSTAL_KEYS = {
    oscillator: {
        type: "triangle",
    },
    envelope: {
        attack: 0.02,
        decay: 0.3,
        sustain: 0.5,
        release: 0.4,
    },
};

export const DEEP_ORGAN = {
    oscillator: {
        type: "sine",
    },
    envelope: {
        attack: 0.1,
        decay: 0.2,
        sustain: 0.7,
        release: 1.0,
    },
};

export const BRIGHT_TRUMPET = {
    oscillator: {
        type: "square",
    },
    envelope: {
        attack: 0.05,
        decay: 0.1,
        sustain: 0.6,
        release: 0.3,
    },
};

export const SOFT_FLUTE = {
    oscillator: {
        type: "sine",
    },
    envelope: {
        attack: 0.1,
        decay: 0.2,
        sustain: 0.5,
        release: 0.5,
    },
};

export const METALLIC_DRUM = {
    oscillator: {
        type: "sawtooth",
    },
    envelope: {
        attack: 0.01,
        decay: 0.1,
        sustain: 0.3,
        release: 0.2,
    },
};

export const SPACE_VOICE = {
    oscillator: {
        type: "fatsine",
        count: 4,
        spread: 40,
    },
    envelope: {
        attack: 0.3,
        decay: 0.2,
        sustain: 0.8,
        release: 0.6,
    },
};

export const ELECTRO_HARP = {
    oscillator: {
        type: "triangle",
    },
    envelope: {
        attack: 0.05,
        decay: 0.15,
        sustain: 0.4,
        release: 0.3,
    },
};

export const VINTAGE_ORGAN = {
    oscillator: {
        type: "square",
    },
    envelope: {
        attack: 0.1,
        decay: 0.3,
        sustain: 0.6,
        release: 0.7,
    },
};

export const FUTURE_PAD = {
    oscillator: {
        type: "fatsawtooth",
        count: 5,
        spread: 60,
    },
    envelope: {
        attack: 1.0,
        decay: 0.5,
        sustain: 0.9,
        release: 1.5,
    },
};

export const DIGITAL_CHIME = {
    oscillator: {
        type: "sine",
    },
    envelope: {
        attack: 0.02,
        decay: 0.2,
        sustain: 0.3,
        release: 0.4,
    },
};
