import React, { useState, useEffect, useCallback, useRef } from "react"
import styled from "styled-components"
import "react-toastify/dist/ReactToastify.css"
import PlaySongProgressBar from "./progressbar/PlaySongProgressBar"
import * as Tone from "tone"
import { showToastInfo } from "../style/ShowToast"
import {
    loadSynth,
    disposeSynths,
    createBufferedMidiEvents,
} from "../util/PlayerUtil.js"
import { delay } from "../util/AppUtil.js"
import PlayerControls from "./PlayerControls"
import { MELOGY_BACKGROUND_XDARK_GREY } from "../style/colors.js"

const PlayerContainerBackground = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PlayerContainer = styled.div`
    width: 100%;
    height: 64px;
    max-width: 960px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PlayerBackground = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ProgressContainer = styled.div`
    height: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
`

const ScrollbarOverlay = styled.div`
    position: absolute;
    top: -5px;
    right: -1px;
    width: 6px;
    height: 6px;
    background-color: ${MELOGY_BACKGROUND_XDARK_GREY};
    z-index: 1;
`

const Player = ({
    songs,
    setSelectedSong,
    selectedInstruments,
    selectedSong,
    createAndFetchMidi,
}) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [totalDuration, setTotalDuration] = useState(0)
    const [pauseTime, setPauseTime] = useState(0)
    const [activeSynths, setActiveSynths] = useState([])
    const [isToneInitialized, setIsToneInitialized] = useState(false)
    const midi = selectedSong ? selectedSong.midi : null
    const prevSelectedSong = useRef(null)
    const [isLooping, setIsLooping] = useState(false)
    const [isAutoPlayEnabled, setIsAutoPlayEnabled] = useState(false)
    const [isInitializing, setIsInitializing] = useState(false)

    // Initialize Tone and Synths
    const initializeTone = async () => {
        if (!isToneInitialized) {
            const newSynths = []
            for (let i = 0; i < selectedInstruments.length; i++) {
                newSynths[i] = await loadSynth(selectedInstruments[i])
            }
            // Dispose of old synths to free memory
            disposeSynths(activeSynths)

            setActiveSynths(newSynths)
            setIsToneInitialized(true)
        }
    }

    // Update Synths
    useEffect(() => {
        const updateSynths = async () => {
            if (!isToneInitialized) {
                return
            }
            const newSynths = []
            for (let i = 0; i < selectedInstruments.length; i++) {
                newSynths[i] = await loadSynth(selectedInstruments[i])
            }
            // Dispose of old synths to free memory
            disposeSynths(activeSynths)

            setActiveSynths(newSynths)
        }
        updateSynths()
    }, [selectedInstruments, isToneInitialized])

    const updateSynthVolumes = () => {
        selectedInstruments.forEach((instrument, index) => {
            const synth = activeSynths[index]

            if (synth) {
                synth.volume.rampTo(instrument.volume, 0.1)
            }
        })
    }

    useEffect(() => {
        if (isToneInitialized) {
            updateSynthVolumes()
        }
    }, [selectedInstruments, isToneInitialized])

    const handlePlayPauseClick = async () => {
        if (!isToneInitialized) {
            setIsInitializing(true)
            await Tone.loaded()
            await Tone.start()
            await initializeTone()
            await delay(2000)
            setIsInitializing(false)
        }
        if (isPlaying) {
            if (isAutoPlayEnabled) {
                setIsAutoPlayEnabled(false)
            }

            if (isLooping) {
                setIsLooping(false)
            }

            setPauseTime(Tone.Transport.seconds)
            Tone.Transport.pause()
        } else {
            if (Math.abs(pauseTime) < 1e-5) {
                await loadAndPlayMidi()
            } else {
                Tone.Transport.start("+0.1", pauseTime)
            }
        }
        setIsPlaying(!isPlaying)
    }

    const handleStepBackwardClick = () => {
        const startPosition = "0:0:0"
        if (Tone.Transport.position === startPosition) {
            const currentIndex = songs.findIndex(
                (song) => song.name === selectedSong.name
            )
            let prevIndex = currentIndex - 1
            if (prevIndex < 0) {
                prevIndex = songs.length - 1
            }
            setSelectedSong(songs[prevIndex])
        } else {
            Tone.Transport.stop()
            Tone.Transport.position = 0
            setProgress(0)
            setPauseTime(0)
        }

        if (isPlaying) {
            loadAndPlayMidi()
        }
    }

    const handleStepForwardClick = async () => {
        const currentIndex = songs.findIndex(
            (song) => song.name === selectedSong.name
        )
        const nextIndex = (currentIndex + 1) % songs.length // This will loop back to 0 if it's the last song
        setSelectedSong(songs[nextIndex])

        Tone.Transport.stop()
        Tone.Transport.cancel()

        setProgress(0)
        setPauseTime(0)
        Tone.Transport.position = 0
    }

    const activeParts = [] // Global or useState variable to store active Parts.

    const loadAndPlayMidi = useCallback(async () => {
        try {
            // Cancel all scheduled events from the beginning.
            Tone.Transport.cancel(0)

            // Stop all active Part objects.
            activeParts.forEach((part) => part.stop(0))

            // Clear the list of active Parts.
            activeParts.length = 0

            await Tone.start().catch((error) => {
                console.log("Couldn't start AudioContext:", error)
            })

            if (activeSynths.length === 0) {
                console.log("No synths loaded.")
                return
            }

            const { parts, maxDuration } = await createBufferedMidiEvents(
                midi.tracks,
                activeSynths,
                selectedInstruments
            )

            // Add the new parts to the activeParts list.
            parts.forEach((part) => {
                activeParts.push(part)
                part.start(0)
            })

            setTotalDuration(maxDuration)
            Tone.Transport.start("+0.1")

            Tone.Transport.scheduleOnce(() => {
                if (isLooping) {
                    Tone.Transport.position = 0
                    loadAndPlayMidi()
                } else {
                    Tone.Transport.stop()
                    Tone.Transport.position = 0
                    setPauseTime(0)
                    setProgress(0)
                    setIsPlaying(false)
                }
            }, maxDuration)
        } catch (err) {
            console.error("An error occurred in loadAndPlayMidi: ", err)
        }
    }, [selectedInstruments, activeSynths, midi, isLooping])

    // Update progress
    useEffect(() => {
        if (isPlaying) {
            const interval = setInterval(() => {
                const newProgress = Tone.Transport.seconds / totalDuration
                setProgress(newProgress * 100)
            }, 100)
            return () => clearInterval(interval)
        }
    }, [isPlaying, totalDuration])

    // Update active synths and play midi
    useEffect(() => {
        if (isPlaying) {
            loadAndPlayMidi()
        }
    }, [activeSynths, isPlaying, loadAndPlayMidi])

    // Handle song change
    useEffect(() => {
        if (selectedSong) {
            if (prevSelectedSong.current !== selectedSong) {
                Tone.Transport.stop()
                Tone.Transport.position = 0
                setPauseTime(0)
                setProgress(0)

                if (isAutoPlayEnabled) {
                    setIsPlaying(true)
                }

                if (isPlaying) {
                    loadAndPlayMidi()
                }
                prevSelectedSong.current = selectedSong
            }
        }
    }, [selectedSong, isPlaying, loadAndPlayMidi])

    useEffect(() => {
        if (!isPlaying && isAutoPlayEnabled) {
            createAndFetchMidi()
        }
    }, [isPlaying])

    // Helper Functions for Drag and Click
    const getProgressAndTime = (e) => {
        const progressBar = document.querySelector(".progress-bar")
        const { left, width } = progressBar.getBoundingClientRect()
        const x = e.clientX - left
        const newProgress = Math.min(Math.max((x / width) * 100, 0), 100)
        const newTime = (newProgress / 100) * totalDuration
        return { newProgress, newTime }
    }

    const handleDrag = (e) => {
        const { newProgress, newTime } = getProgressAndTime(e)
        setProgress(newProgress)
        Tone.Transport.seconds = newTime
        setPauseTime(newTime)
    }

    const handleClick = (e) => {
        const { newProgress, newTime } = getProgressAndTime(e)
        setProgress(newProgress)
        Tone.Transport.seconds = newTime
        if (isPlaying) {
            Tone.Transport.start("+0.1", newTime)
        } else {
            setPauseTime(newTime === 100 ? 0 : newTime)
        }
    }

    const toggleIsLooping = () => {
        if (!isLooping && isAutoPlayEnabled) {
            setIsAutoPlayEnabled(false)
        }

        setIsLooping(!isLooping)
    }

    const toggleAutoPlay = () => {
        if (isLooping && !isAutoPlayEnabled) {
            setIsLooping(false)
        }

        if (!isAutoPlayEnabled) {
            showToastInfo("Create and play new songs continuously", 5000)
        }

        handlePlayPauseClick()
        setIsAutoPlayEnabled(!isAutoPlayEnabled)
    }

    return (
        <PlayerContainerBackground>
            <PlayerContainer>
                <PlayerBackground>
                    {midi && (
                        <>
                            <ProgressContainer>
                                <ScrollbarOverlay />
                                <PlaySongProgressBar
                                    progress={progress}
                                    handleDrag={handleDrag}
                                    handleClick={handleClick}
                                />
                            </ProgressContainer>
                            <PlayerControls
                                handleStepBackwardClick={
                                    handleStepBackwardClick
                                }
                                handlePlayPauseClick={handlePlayPauseClick}
                                handleStepForwardClick={handleStepForwardClick}
                                isPlaying={isPlaying}
                                handlePlayLoopClick={toggleIsLooping}
                                isLooping={isLooping}
                                toggleAutoPlay={toggleAutoPlay}
                                isAutoPlayEnabled={isAutoPlayEnabled}
                                isInitializing={isInitializing}
                            />
                        </>
                    )}
                </PlayerBackground>
            </PlayerContainer>
        </PlayerContainerBackground>
    )
}

export default Player
