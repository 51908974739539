import React from "react"
import styled from "styled-components"
import { FaAngleDown, FaAngleRight } from "react-icons/fa"
import { MELOGY_TRANSPARENT, SECONDARY } from "../../style/colors"
import StyleSetting from "./StyleSetting"
import Button from "../Button"

const SettingsContainer = styled.div`
    width: calc(100% - 16px);
    height: 100%;
    max-width: 960px;
    min-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ isVerticalScrollVisible }) =>
        isVerticalScrollVisible ? "flex-start" : "center"};
`

const SettingsBackground = styled.div`
    width: 100%;
    height: 100%;
    color: ${SECONDARY};
    background-color: ${MELOGY_TRANSPARENT};
`

const SettingsGroup = styled.div`
    margin-left: 8px;
`

function StyleSettings({
    updateStyleSettings,
    isVerticalScrollVisible,
    isHorizontalScrollVisible,
    expandedStyleSettingGroups,
    groupedStyleSettings,
    toggleStyleSettingsGroup,
}) {

    return (
        <SettingsContainer 
            isVerticalScrollVisible={isVerticalScrollVisible} 
            isHorizontalScrollVisible={isHorizontalScrollVisible}
        >
            <SettingsBackground>
                {Object.entries(groupedStyleSettings).map(([groupName, settings]) => (
                    <SettingsGroup key={groupName}>
                        <Button
                            text={groupName}
                            height="32px"
                            width="168px"
                            justifyContent="flex-start"
                            buttonColor="secondary"
                            onClick={() => toggleStyleSettingsGroup(groupName)}
                            icon={expandedStyleSettingGroups[groupName] ? 
                                <FaAngleDown style={{ fontSize: "16px" }} /> : 
                                <FaAngleRight style={{ fontSize: "16px" }} />
                            }
                        />
                        {expandedStyleSettingGroups[groupName] &&
                            settings.map((styleSetting) => (
                                <StyleSetting
                                    key={styleSetting.name}
                                    styleSetting={styleSetting}
                                    updateStyleSettings={updateStyleSettings}
                                />
                            ))}
                    </SettingsGroup>
                ))}
            </SettingsBackground>
        </SettingsContainer>
    )
}

export default StyleSettings
