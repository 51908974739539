export const defaultStyleSettings = [
    {
        name: "songStructure",
        value: "ABA",
        inputType: "SLIDER",
        group: "Form",
        possibleValues: [
            "Random",
            "A",
            "ABA",
            "AABA",
            "ABABA",
            "ABACA",
            "ABCBA",
            "ABACABA",
            "ABCABCA",
        ],
    },
    {
        name: "root",
        value: "C",
        inputType: "SLIDER",
        group: "Key",
        possibleValues: [
            "Random",
            "C",
            "C♯",
            "D",
            "D♯",
            "E",
            "F",
            "F♯",
            "G",
            "G♯",
            "A",
            "A♯",
            "B",
        ],
    },
    {
        name: "key",
        value: "Major",
        inputType: "SLIDER",
        group: "Key",
        possibleValues: [
            "Random",
            "Major",
            "Minor",
            "Harmonic major",
            "Harmonic minor",
            "Melodic major",
            "Melodic minor",
            "Double harmonic major",
            "Double harmonic minor",
        ],
    },
    {
        name: "mode",
        value: "4 Lydian",
        inputType: "SLIDER",
        group: "Key",
        key: "Major",
        possibleValues: [
            {
                key: "Random",
                values: ["Random"],
            },
            {
                key: "Major",
                values: [
                    "1 Ionian",
                    "2 Dorian",
                    "3 Phrygian",
                    "4 Lydian",
                    "5 Mixolydian",
                    "6 Aeolian",
                    "7 Locrian",
                ],
            },
            {
                key: "Minor",
                values: [
                    "1 Aeolian",
                    "2 Locrian",
                    "3 Ionian",
                    "4 Dorian",
                    "5 Phrygian",
                    "6 Lydian",
                    "7 Mixolydian",
                ],
            },
            {
                key: "Harmonic major",
                values: [
                    "1 Harmonic Major",
                    "2 Dorian ♭5",
                    "3 Phrygian ♭4",
                    "4 Lydian Minor",
                    "5 Mixolydian ♭2",
                    "6 Lydian Augmented ♯2",
                    "7 Locrian ♭♭3",
                ],
            },
            {
                key: "Harmonic minor",
                values: [
                    "1 Harmonic Minor",
                    "2 Locrian ♮6",
                    "3 Ionian ♯5",
                    "4 Dorian ♯4",
                    "5 Phrygian Dominant",
                    "6 Lydian ♯2",
                    "7 Super Locrian ♭♭7",
                ],
            },

            {
                key: "Melodic major",
                values: ["1 Melodic Major", "2", "3", "4", "5", "6", "7"],
            },
            {
                key: "Melodic minor",
                values: [
                    "1 Melodic Minor",
                    "2 Dorian ♭2",
                    "3 Lydian Augmented",
                    "4 Lydian Dominant",
                    "5 Mixolydian ♭6",
                    "6 Locrian ♯2",
                    "7 Altered Scale",
                ],
            },
            {
                key: "Double harmonic major",
                values: [
                    "1 Double Harmonic Major",
                    "2 Lydian ♯2 ♯6",
                    "3 Ultra Phrygian",
                    "4 Hungarian Minor",
                    "5 Oriental",
                    "6 Ionian Augmented ♯2",
                    "7 Locrian ♭♭3 ♭♭7",
                ],
            },
            {
                key: "Double harmonic minor",
                values: [
                    "1 Double Harmonic Minor",
                    "2 Lydian ♯3 ♯6",
                    "3 Ultra Phrygian Dominant",
                    "4 Hungarian Major",
                    "5 Oriental",
                    "6 Ionian Augmented",
                    "7 Super Locrian bb7",
                ],
            },
        ],
    },
    {
        name: "intensityOfChords",
        lowerValue: 1,
        higherValue: 3,
        inputType: "RANGE_SLIDER",
        possibleValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    },
    {
        name: "intensityOfMelody",
        lowerValue: 2,
        higherValue: 6,
        inputType: "RANGE_SLIDER",
        possibleValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    },
    {
        name: "motiveComplexityOfMelody",
        lowerValue: 3,
        higherValue: 10,
        inputType: "RANGE_SLIDER",
        possibleValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
        name: "motiveComplexityOfChords",
        lowerValue: 1,
        higherValue: 8,
        inputType: "RANGE_SLIDER",
        possibleValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
        name: "overall extended chord rate",
        value: "2",
        inputType: "SLIDER",
        group: "Chords",
        possibleValues: [
            "Random",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
        ],
    },
    {
        name: "7th chord rate",
        value: "3",
        inputType: "SLIDER",
        group: "Chords",
        possibleValues: [
            "Random",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
        ],
    },
    {
        name: "9th chord rate",
        value: "3",
        inputType: "SLIDER",
        group: "Chords",
        possibleValues: [
            "Random",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
        ],
    },
    {
        name: "11th chord rate",
        value: "3",
        inputType: "SLIDER",
        group: "Chords",
        possibleValues: [
            "Random",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
        ],
    },
    {
        name: "13th chord rate",
        value: "3",
        inputType: "SLIDER",
        group: "Chords",
        possibleValues: [
            "Random",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
        ],
    },
]
