import React from "react"
import styled from "styled-components"
import { MELOGY_TRANSPARENT, SECONDARY } from "../../style/colors"
import Track from "./Track"

const TracksContainer = styled.div`
    width: 100%;
    max-width: 960px;
    min-width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const TracksBackground = styled.div`
    width: 100%;
    height: 100%;
    color: ${SECONDARY};
    background-color: ${MELOGY_TRANSPARENT};
`

function Tracks({
    selectedInstruments,
    selectInstrument,
    isVerticalScrollVisible,
    isHorizontalScrollVisible,
    handleVolumeChange,
    setSelectedInstruments,
}) {
    return (
        <TracksContainer>
            <TracksBackground>
                {selectedInstruments.map((instrument, index) => (
                    <Track
                        key={index}
                        trackNumber={index + 1}
                        selectedInstrument={instrument}
                        selectInstrument={selectInstrument}
                        isVerticalScrollVisible={isVerticalScrollVisible}
                        isHorizontalScrollVisible={isHorizontalScrollVisible}
                        handleVolumeChange={handleVolumeChange}
                        selectedInstruments={selectedInstruments}
                        setSelectedInstruments={setSelectedInstruments}
                    />
                ))}
            </TracksBackground>
        </TracksContainer>
    )
}

export default Tracks
