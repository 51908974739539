import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { 
    SECONDARY,
    SECONDARY_4XDIM,
    SECONDARY_HIGHLIGHT, 
} from "../../style/colors"
import {
    FONT_SIZE_2XS,
    TITLE_FONT_FAMILY,
    TITLE_LETTER_SPACING,
    TITLE_FONT_WEIGHT,
} from "../../style/fontSettings"
import Button from "../Button"

const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 80vh; /* Set a max height for the container */
`

const Title = styled.div`
    height: 32px;
    font-size: ${FONT_SIZE_2XS};
    color: ${SECONDARY};
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`

const TitleText = styled.div`
    height: 32px;
    font-family: ${TITLE_FONT_FAMILY};
    font-weight: ${TITLE_FONT_WEIGHT};
    letter-spacing: ${TITLE_LETTER_SPACING};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
`
const ListContainerContainer = styled.div`
    flex-grow: 1;
    max-height: calc(min(80vh, 480px) - 64px); 
    overflow: hidden;
    width: 100%; /* Ensure it takes the full width of the ContentContainer */
`

const ListContainer = styled.div`
    flex-grow: 1;
    max-height: calc(min(80vh, 480px) - 64px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: ${({ isOverflowing }) => (isOverflowing ? "auto" : "hidden")};
    overflow-x: hidden;

    /* Custom scrollbar styles for WebKit-based browsers */
    &::-webkit-scrollbar {
        width: 4px; 
    }
    &::-webkit-scrollbar-track {
        background: ${SECONDARY_4XDIM};
    }
    &::-webkit-scrollbar-thumb {
        background: ${SECONDARY};
    }
    &::-webkit-scrollbar-thumb:hover {
        background: ${SECONDARY_HIGHLIGHT};
    }
`

const PopoverContentContainer = ({
    title,
    list = [], // Default to an empty array if list is undefined
    onClick,
    component,
    selectedListItem,
}) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const contentContainerRef = useRef(null);

    const calculateOverflow = () => {
        if (!list || list.length === 0) return; // Only calculate if list exists and is not empty

        const buttonHeight = 32; // Height of each button
        const totalButtonHeight = list.length * buttonHeight;

        if (contentContainerRef.current) {
            const availableHeight = contentContainerRef.current.clientHeight;
            setIsOverflowing(totalButtonHeight > availableHeight);
        }
    };

    useEffect(() => {
        calculateOverflow();

        const handleResize = () => {
            calculateOverflow();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [list]);

    return (
        <ContentContainer ref={contentContainerRef}>
            <Title>
                <TitleText>{title}</TitleText>
            </Title>
            <ListContainerContainer>
                <ListContainer isOverflowing={isOverflowing}>
                    {list.map((item, index) => (
                        <Button
                            key={index}
                            text={item}
                            height="32px"
                            width="168px"
                            justifyContent="flex-start"
                            isSelected={item === selectedListItem}
                            buttonColor="secondary"
                            onClick={(event) => {
                                onClick(event, item);
                            }}
                        />
                    ))}
                    {component && component}
                </ListContainer>
            </ListContainerContainer>
        </ContentContainer>
    )
}

export default PopoverContentContainer
